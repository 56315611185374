import { useContext, useState, useEffect } from 'react';
import { Box, List, IconButton, styled, ListItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { ResponseLogin } from 'src/models/login';
import { TokenContext } from 'src/contexts/UserContext';
import { createStyles, makeStyles } from '@mui/styles';
import { ListItemText, Theme } from '@material-ui/core';

import MARCA_IMG from '../../../../assets/imagen/MARCA_IMG.png';

import tablerosSIDENAVACTIVE from '../../../../assets/icons/SIDEBAR/tablerosSIDENAVACTIVE.svg';
import tablerosSIDENAVPASIVE from '../../../../assets/icons/SIDEBAR/tablerosSIDENAVPASIVE.svg';

import inforestACTIVE from '../../../../assets/icons/SIDEBAR/inforestACTIVE.svg';
import inforestPASIVE from '../../../../assets/icons/SIDEBAR/inforestPASIVE.svg';
import docHover from '../../../../assets/icons/SIDEBAR/SIDEBAR-COMPROBANTE-HOVER_68X56.svg';
import bajaHover from '../../../../assets/icons/SIDEBAR/SIDEBAR-BAJAS-HOVER_68X56.svg';
import sbClose from '../../../../assets/icons/SIDEBAR/SIDEBAR CERRAR_52X48.svg';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: '${theme.colors.alpha.trueWhite[50]}';
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      zIndex: 1,
      backgroundColor: 'white',
      position: 'fixed',
      boxShadow: '4px 0px 10px 0px rgba(25, 67, 127, 0.12)',
      '& .MuiButtonBase-root': {
        padding: 0
      },
      '& .MuiList-root': {
        padding: '0px'
      }
    },
    paper: {
      position: 'absolute',
      top: 0,
      zIndex: 1,
      padding: 0,
      '&.MuiPaper-root': {
        boxShadow: '8px 8px 10px 0px rgba(25, 67, 127, 0.10)',
        position: 'absolute',
        top: 0,
        zIndex: 1,
        padding: 0
      }
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      position: 'fixed',
      '&.MuiListItem-root': {
        justifyContent: 'center',
        padding: '20px'
      }
    }
  })
);

interface Props {
  response: ResponseLogin;
}

const SidebarMenu = ({ response }: Props) => {
  const classes = useStyles();
  const { idSelectedIndexMenu } = useContext(TokenContext);
  const [hoveredItem, setHoveredItem] = useState<any | null>(null);
  const { toggleSidebar } = useContext(SidebarContext);
  const [tesoreria, setTesoreria] = useState(response.role);
  const [activeRoute, setActiveRoute] = useState<string | null>(null);

  useEffect(() => {
    const storedRoute = localStorage.getItem('ruta');
    if (storedRoute) {
      setActiveRoute(storedRoute);
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'ruta') {
        setActiveRoute(event.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const accesos_nav = response.acceso;

  const handleListItemClick = (index: any) => {
    idSelectedIndexMenu(index);
    const route = menuItems[index].rutaPrincipal;
    navigate(route);
    localStorage.setItem('ruta', route);
    setActiveRoute(route);
  };

  const menuItems = [
    {
      imgPasivo: tablerosSIDENAVPASIVE,
      imgHover: docHover,
      imgActivo: tablerosSIDENAVACTIVE,
      descripcion: 'Comprobantes de Pago',
      rutaPrincipal: '/menu/mistableros',
      opciones: [],
      activo: accesos_nav.comprobantes
    },
    {
      imgPasivo: inforestPASIVE,
      imgHover: bajaHover,
      imgActivo: inforestACTIVE,
      descripcion: 'Reporte de Bajas',
      rutaPrincipal: '/menu/correlativos/pedidos',
      opciones: [],
      activo: accesos_nav.bajas
    }
    // {
    //   imgPasivo: INFOHOTELSIDENAV,
    //   imgHover: guiaHover,
    //   imgActivo: INFOHOTELACTIVATE,
    //   descripcion: 'Guías',
    //   rutaPrincipal: '/menu/correlativos/infotel',
    //   opciones: [],
    //   activo: accesos_nav.guias
    // },
    // {
    //   imgPasivo: INFOBACKSIDENAV,
    //   imgHover: cobranzaHover,
    //   imgActivo: INFOBACKACTIVATE,
    //   descripcion: 'Administración de Locales',
    //   rutaPrincipal: '/menu/correlativos/infoback',
    //   opciones: [],
    //   activo: tesoreria === 'COBRANZA' || tesoreria === 'ADMIN' ? true : false
    // },
    // {
    //   imgPasivo: CLIENTESSIDENAV,
    //   imgHover: cobranzaHover,
    //   imgActivo: CLIENTESACTIVATE,
    //   descripcion: 'Administración de Locales',
    //   rutaPrincipal: '/menu/correlativos/clientes',
    //   opciones: [],
    //   activo: tesoreria === 'COBRANZA' || tesoreria === 'ADMIN' ? true : false
    // }
  ];

  const navigate = useNavigate();

  const handleMouseEnter = (item: any) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <>
      <Box
        className={classes.root}
        sx={{
          width: '68px !important',
          paddingTop: {
            xl: '20px',
            lg: '20px',
            md: '20px',
            sm: '6px',
            xs: '6px'
          }
        }}
      >
        <Box
          sx={{
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'none',
              lg: 'none',
              xl: 'none'
            }
          }}
        >
          <IconButton color="primary" onClick={toggleSidebar}>
            <img src={sbClose} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '140px'
          }}
        >
          <img
            src={MARCA_IMG}
            alt="MARCA_IMG"
            style={{
              width: '60px',
              height: '60px',
              flexShrink: 0
            }}
          />
        </Box>
        <List component="nav">
          {menuItems.map((o, index) => (
            <ListItem
              key={index}
              className={classes.listItem}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleListItemClick(index)}
              sx={{
                paddingX: '10px',
                alignItems: 'center',
                height: '45px',
                marginBottom: '24px',
                cursor: o.opciones.length === 0 ? 'pointer' : ''
              }}
            >
              <ListItemText />
              <img
                src={
                  hoveredItem === index || activeRoute === o.rutaPrincipal
                    ? o.imgActivo
                    : o.imgPasivo
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
};

export default SidebarMenu;
