import React, { useState } from 'react';
import { Box, MenuItem, Typography } from '@mui/material';
import MARCA_IMG from '../../../../assets/imagen/MARCA_IMG.png';
import ArrowDropDownIcon from '../../../../assets/icons/BOTONES/ARROW_SIDENAV.svg';
import CHANGEDMARCA from '../../../../assets/icons/BOTONES/CHANGEDMARCA.svg';
import INFOHotelActive from '../../../../assets/icons/SIDEBAR/INFOhotel-ACTIVE.svg';
import ClientesACTIVE from '../../../../assets/icons/SIDEBAR/Clientes-ACTIVE.svg';
import INFORestActive from '../../../../assets/icons/SIDEBAR/INFOrest_ACTIVE.svg';
import PlantillasACTIVE from '../../../../assets/icons/SIDEBAR/Plantillas-ACTIVE.svg';
import FlechaHaciaAbajoSIDENAV from '../../../../assets/icons/SIDEBAR/FlechaHaciaAbajoSIDENAV.svg';
import ADDSUBPESTAÑA from '../../../../assets/icons/SIDEBAR/ADDSUBPESTAÑA.svg';
import FlechaHorizontalSIDENAV from '../../../../assets/icons/SIDEBAR/FlechaHorizontalSIDENAV.svg';
import SelectedListItem from './SidebarSuperMenu/SelectedListItem';
import CustomizedMenusMarcas from '../MenuTool/MenuToolMarcas';

const SidebarMenuGrouped = () => {
  const [openItems, setOpenMenuToolMarcas] = useState(true);

  const changeBrand = () => {
    setOpenMenuToolMarcas(true);
  };
  const marcas = [
    'La Victoria Restobar',
    'La Victoria Café',
    'El Girasol Terrestre',
    'Mister Satum'
  ];

  const menuDataInforest = [
    {
      status: false,
      title: 'Mis Tableros',
      icon: PlantillasACTIVE,
      submenus: [
        {
          status: true,
          title: 'INFOrest',
          icon: FlechaHaciaAbajoSIDENAV,
          rutaPrincipal: '/menu/mistableros'
        },
        {
          status: true,
          title: 'INFOhotel',
          icon: FlechaHaciaAbajoSIDENAV,
          rutaPrincipal: '/menu/mistableros'
        },
        {
          status: true,
          title: 'INFOback',
          icon: FlechaHaciaAbajoSIDENAV,
          rutaPrincipal: '/menu/mistableros'
        }
      ]
    },
    {
      status: false,
      title: 'INFOrest',
      icon: INFORestActive,
      submenus: [
        {
          nivel: 2,
          title: 'Correlativos',
          icon: ADDSUBPESTAÑA,
          subitems: [
            {
              status: true,
              indice: 2,
              title: 'Pedidos',
              rutaPrincipal: '/menu/correlativos/pedidos',
              icon: FlechaHorizontalSIDENAV
            },
            {
              status: true,
              indice: 2,
              title: 'Documentos',
              rutaPrincipal: '/menu/correlativos/pedidos/consulta',
              icon: FlechaHorizontalSIDENAV
            },
            {
              status: true,
              indice: 2,
              title: 'Cobranzas',
              rutaPrincipal: '/menu/correlativos/pedidos',
              icon: FlechaHorizontalSIDENAV
            }
          ]
        },
        {
          status: false,
          title: 'Reportes',
          icon: ADDSUBPESTAÑA,
          subitems: [
            {
              status: false,
              indice: 2,
              title: 'Contables',
              icon: FlechaHaciaAbajoSIDENAV,
              subitems: [
                {
                  status: true,
                  indice: 2,
                  title: 'Registros de Venta',
                  rutaPrincipal: '/menu/correlativos/pedidos'
                }
              ]
            },
            {
              status: false,
              indice: 2,
              title: 'De control',
              icon: FlechaHaciaAbajoSIDENAV,
              subitems: [
                {
                  status: true,
                  indice: 2,
                  title: 'Palote de Producción',
                  rutaPrincipal: '/menu/correlativos/pedidos/consulta'
                }
              ]
            }
          ]
        },
        {
          title: 'KPIs',
          icon: ADDSUBPESTAÑA,
          subitems: []
        }
      ]
    },
    {
      status: true,
      title: 'Clientes',
      icon: ClientesACTIVE,
      rutaPrincipal: '/menu/clientes',
      submenus: []
    }
  ];

  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemSelect = (item) => {
    setSelectedItem(item);
  };

  return (
    <Box
      sx={{
        width: '320px',
        background: 'white',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        zIndex: 10,
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '180px',
          padding: '20px',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexShrink: 0,
          alignSelf: 'stretch'
        }}
      >
        {/* TITULO DE MARCA */}
        <Box
          sx={{
            display: 'flex',
            width: '280px',
            height: '24px',
            padding: '10px 12px',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '20px',
            background: '#EDF4FD'
          }}
        >
          <Typography
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              flex: '1 0 0',
              overflow: 'hidden',
              color: 'var(--texto-900-contraste, #2664C7)',
              textAlign: 'center',
              textOverflow: 'ellipsis',
              fontFamily: 'Poppins',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '14px'
            }}
          >
            Servicios Alimentarios Vituña Sociedad Anónima - SAVIT S.A.
          </Typography>
        </Box>

        {/* IMAGEN Y SELECCIÓN DE MARCA */}
        <Box display={'flex'}>
          <Box
            sx={{
              width: '100px',
              height: '100px',
              flexShrink: 0,
              borderRadius: '4px',
              border: '1px solid #F5F6F7',
              background: `url('https://s3-alpha-sig.figma.com/img/b4bf/82b5/357b85b8c02a6968f0f1cfc82149be43?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PW39AewNEsVicAS9Yb-jpodl5yS6yI1rJ436rYAzv9fbzEeaLbMcRUsr9WuvRcivWzWJdUQy-Y1UyQbueM2ppx7swm4fUehQW2RCMbwG1MozWeD82a~ipcIBbzV6g4UkMoUS9n9HFl9GmtmwPddQhm8scprCSzCuCwfBl0hUJoFQAp5-mOlqesgnO2dXMmEqRPRqAZ6YUDxebsjarasPEFEC8KBPpzEiw~Ry7QWzXtb3YRQmz-7JNnuXbEaoOdQmcWNqme9yrYNC1Q5J~pH8BsT7XdaRIe20-gnLCNNIG~6-LVxkRBWwVhoktJuveaYG~Dh0qvBSRIWLoLMOiA-UpQ__')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}
          ></Box>
          <Box
            sx={{
              display: 'flex',
              padding: '12px 4px 12px 8px',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CustomizedMenusMarcas marcas={marcas} />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          paddingX: 0
        }}
      >
        <SelectedListItem menuData={menuDataInforest} />
      </Box>
    </Box>
  );
};

export default SidebarMenuGrouped;
