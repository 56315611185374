import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import SidebarLayout from 'src/layouts/SidebarLayout';
import LoaderScreen from 'src/content/pages/Status/Loading/LoaderScreen';

import ProtectedRoute from '../authenticate/protectedRoutes';
import PublicRoute from '../authenticate/publicRoutes';
import AdmPedidos from 'src/content/menu/Pedidos';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<LoaderScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loader(lazy(() => import('src/content/pages/Login')));

const Home = Loader(lazy(() => import('src/content/home_index')));
const ResumenBajas = Loader(
  lazy(() => import('src/content/menu/ConsultaPedidos'))
);
const MisTableros = Loader(
  lazy(() => import('src/content/menu/Dashboard/index'))
);
const Clientes = Loader(lazy(() => import('src/content/menu/Clientes/index')));
const TablerosKPIs = Loader(
  lazy(() => import('src/content/menu/TablerosKPIs/index'))
);
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);

const Ruta = () => {
  return (
    <Routes>
      {/* ROUTE DEL MAIN (PUBLICAS) */}
      <Route path="status/404" element={<Status404 />} />
      <Route path="*" element={<Status404 />} />
      {/* ROUTE DEL MAIN (PRIVADAS) */}
      <Route
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="menu"
        element={
          <ProtectedRoute>
            <SidebarLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path="/menu/clientes"
          element={
            <ProtectedRoute>
              <Clientes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/menu/mistableros"
          element={
            <ProtectedRoute>
              <MisTableros />
            </ProtectedRoute>
          }
        />
        <Route
          path="/menu/mistableroskpis"
          element={
            <ProtectedRoute>
              <TablerosKPIs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/menu/correlativos/pedidos"
          element={
            <ProtectedRoute>
              <AdmPedidos />
            </ProtectedRoute>
          }
        />
        <Route
          path="/menu/correlativos/pedidos/consulta"
          element={
            <ProtectedRoute>
              <ResumenBajas />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
};
export default Ruta;
