import { Button, ButtonProps, styled } from '@mui/material';
import React, { useState } from 'react';

interface Props {
  handleEvent: React.MouseEventHandler<HTMLElement>;
  title: string;
  pasive: boolean;
  imgIcon?: boolean;
  urlIcon?: string;
  urlIconHover?: string; // Nuevo prop para la URL del icono en hover
  textcolor?: string;
  textcolorHover?: string;
  background?: string;
  hover?: string;
  widthdefault?: string;
  heightdefault?: string;
  iconColorHover?: string; // Nuevo prop para el color del icono en hover
}

const ColorButtonStyle: React.FC<Props> = ({
  handleEvent,
  title,
  pasive,
  imgIcon,
  urlIcon,
  urlIconHover,
  textcolor,
  textcolorHover,
  background,
  hover,
  widthdefault,
  heightdefault,
  iconColorHover // Nuevo prop para el color del icono en hover
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: textcolor,
    backgroundColor: background,
    textTransform: 'uppercase',
    width: widthdefault,
    '&.MuiButtonBase-root': {
      '&.MuiButton-root': {
        border: pasive ? '0px' : '1px solid var(--secundariomenta)'
      }
    },
    '&:hover': {
      borderRadius: '30px',
      background: hover,
      boxShadow: '0px 4px 12px 4px rgba(30, 41, 71, 0.08)',
      color: textcolorHover,
      '& img': {
        content: `url(${urlIconHover})`, // Cambia el contenido de la imagen en hover
        fill: iconColorHover // Cambiar el color del icono SVG en hover
      }
    },
    '&:not(:hover)': {
      '& img': {
        content: `url(${urlIcon})`, // Vuelve al contenido normal de la imagen cuando no está en hover
        fill: 'inherit' // Asegura que el color del icono sea el predeterminado
      }
    },
    fontWeight: 500,
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    display: 'flex !important',
    borderRadius: '30px',
    boxShadow: '0px 4px 12px 4px rgba(30, 41, 71, 0.08)',
    border: pasive ? '' : '1px solid var(--secundariomenta)',
    '&.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)',
      border: 'none' // O cualquier otro estilo que desees para el botón deshabilitado
    }
  }));

  return (
    <ColorButton
      sx={{
        height: {
          xs: heightdefault,
          sm: heightdefault,
          md: heightdefault,
          lg: heightdefault,
          xl: heightdefault
        }
      }}
      variant={'outlined'}
      onClick={handleEvent}
      disabled={pasive}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {imgIcon && (
        <img
          src={isHovered ? urlIconHover : urlIcon}
          alt="icon"
          style={{ marginRight: '8px' }}
        />
      )}
      {title}
    </ColorButton>
  );
};

export default ColorButtonStyle;
