import {
  Box,
  List,
  Grid,
  ListItem,
  Button,
  ListItemText,
  Menu,
  Typography,
  DialogContent,
  MenuItem,
  AppBar,
  Tooltip,
  IconButton,
  Grow,
  Zoom,
  DialogActions,
  Link
} from '@mui/material';
import { useRef, useState, useContext, useEffect } from 'react';
// import * as Animatable from 'react-native-animatable';
import iconUser from '../../../../assets/icons/SIDEBAR/USUARIO GENERICO-ACTIVO_36X36.svg';
import iconAmbar from '../../../../assets/icons/SIDEBAR/ALERTA AMBAR_36X36.svg';
import iconRojo from '../../../../assets/icons/SIDEBAR/ALERTA ROJA_36X36.svg';
import { TokenContext } from 'src/contexts/UserContext';
import { ResponseLogin } from 'src/models/login';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { useTheme } from '@emotion/react';
import sbOpen from '../../../../assets/icons/SIDEBAR/SIDEBAR ABRIR_52X48.svg';
import sbClose from '../../../../assets/icons/SIDEBAR/SIDEBAR CERRAR_52X48.svg';
import DialogUser from 'src/content/pages/Components/Dialog/DialogUsuario';
import { useLottie } from 'lottie-react';
import fotoUser from '../../../../assets/icons/SIDEBAR/MODAL IMAGEN USUARIO_80X80.svg';
import logOutIcon from '../../../../assets/icons/SIDEBAR/MODAL USUARIO-CERRAR SESION-PASIVO_24X24.svg';
import logOutIconAA from '../../../../assets/icons/SIDEBAR/MODAL USUARIO-CERRAR SESION-ACTIVO_24X24.svg';
import DialogScreen from 'src/content/pages/Components/Dialog';
import imagenerror from '../../../../assets/gifs/ALERTA CARTA_112X112.gif';
import ColorButtonStyleModal from 'src/content/pages/Components/ColorButton/ColorButtonStyleModal';
import logoInfoPC from '../../../../assets/icons/SIDEBAR/LOGO NAVBAR-BI-PC_120X24 .svg';
import logoInfoMV from '../../../../assets/icons/BI-RESPONSIVE.svg';
import groovyWalkAnimation from '../../../../assets/json_lottie/efecto_pulse.json';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CSS from 'csstype';
import EstadoPill from 'src/content/pages/Components/Pill/EstadoPill';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import ModalAlerta from 'src/content/pages/Components/Dialog/ModalAlerta';

interface Props {
  response: ResponseLogin;
}

const estilo_efecto: CSS.Properties = {
  opacity: 0.5
};

const HeaderMenu = ({ response }: Props) => {
  const { idToken, idRuta, idResponseLogin } = useContext(TokenContext);
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const [logout, setlogout] = useState(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const usuario = response.name
    .toLowerCase()
    .trim()
    .split(' ')
    .map((v) => v[0].toUpperCase() + v.substr(1))
    .join(' ');
  const rol_user = response.role
    .toLowerCase()
    .trim()
    .split(' ')
    .map((v) => v[0].toUpperCase() + v.substr(1))
    .join(' ');
  //const estado = response.certificado.estado;
  const [estado, setEstado] = useState(response.certificado.estado);
  const [openAlerta, setOpenAlerta] = useState(false);

  const options = {
    animationData: groovyWalkAnimation,
    loop: true
  };

  const { View } = useLottie(options);

  const handleCerrarSesion = () => {
    idRuta(undefined);
    idToken(undefined);
    idResponseLogin(undefined);
    window.location.reload();
  };
  const handleopenSesion = () => {
    setOpenModal(true);
    setOpen(false);
  };
  const handleOpenUser = () => {
    setOpen(true);
    setlogout(false);
  };
  const handleClickAlerta = () => {
    setOpenAlerta(response.certificado.estado !== '0' ? true : false);
    // setOpenAlerta(true);
  };

  const date: Date = new Date(response.certificado.fechavencimiento);
  const fechahoy = Date();
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(anchor);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const handleBackdropClick = (event: React.MouseEvent<HTMLElement>) => {
    // Evita cerrar el diálogo al hacer clic en el fondo
    event.stopPropagation();
  };

  const [opencc, setOpencc] = useState(false);
  const [value, setValue] = useState('Dione');

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue?: string) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  useEffect(() => {
    // if (openAlerta == true) {
    //   setTimeout(() => {
    //     setOpenAlerta(false)
    //   }, 8000)
    // };
    window.addEventListener('load', handleClickAlerta);

    return () => {
      window.removeEventListener('load', handleClickAlerta);
    };
  });

  return (
    <>
      <Box
        sx={{
          // marginLeft: { xl: '68px', lg: '68px', md: '68px', sm: '0px', xs: '0px' },  //margen al nav
          display: 'flex',
          justifyContent: 'right',
          textAlign: 'right',
          height: '100%',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'none',
              lg: 'none',
              xl: 'none'
            },
            width: '100%',
            textAlign: 'left'
          }}
        >
          <IconButton
            color="primary"
            sx={{
              padding: '0px',
              paddingRight: '20px'
            }}
            onClick={toggleSidebar}
          >
            <Box
              sx={{
                display: 'flex',
                width: '52px',
                height: '48px',
                padding: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                borderRadius: '0px 24px 24px 0px',
                backgroundColor: 'var(--boton-800-primario, #3677DE)',
                transform: sidebarToggle
                  ? 'translateX(-100%)'
                  : 'translateX(0)',
                transition: 'transform 0.3s'
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.142 11.7072C14.2865 11.8757 14.2865 12.1243 14.142 12.2929L8.81143 18.5119C8.54186 18.8264 8.57829 19.2999 8.89278 19.5695C9.20727 19.839 9.68075 19.8026 9.95032 19.4881L15.2809 13.2691C15.9069 12.5388 15.9069 11.4612 15.2809 10.731L9.95032 4.51192C9.68075 4.19743 9.20727 4.161 8.89278 4.43057C8.57829 4.70014 8.54186 5.17361 8.81143 5.48811L14.142 11.7072Z"
                  fill="white"
                />
              </svg>
            </Box>
          </IconButton>
          <Box
            sx={{
              display: {
                xs: 'flex',
                sm: 'flex',
                md: 'none',
                lg: 'none',
                xl: 'none'
              },
              textAlign: 'left'
            }}
          >
            <img src={logoInfoMV} />
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'flex',
              lg: 'flex',
              xl: 'flex'
            },
            width: '100%',
            textAlign: 'left'
          }}
        >
          <img src={logoInfoPC} />
        </Box>

        <Box display={'flex'} width={'100%'} justifyContent={'right'}>
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'block',
                xl: 'block'
              },
              paddingRight: '12px',
              alignSelf: 'center'
            }}
          >
            <Typography
              sx={{
                display: {
                  xs: 'none',
                  sm: 'none',
                  md: 'block',
                  lg: 'block',
                  xl: 'block'
                },

                color: 'var(--neutralnegro, #232323)',
                textAlign: 'right',
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px'
              }}
            >
              {response != null || response != undefined
                ? // ? response.name
                  'Martin Fiestas'
                : 'usuario'}
            </Typography>
            <Typography
              sx={{
                color: 'var(--texto-900-contraste, #2664C7)',
                textAlign: 'right',
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: '16px' // 114.286%
              }}
            >
              {response != null || response != undefined
                ? 'Administrador General Jr.'
                : 'usuario'}
            </Typography>
          </Box>
          <Box>
            <img
              src={iconUser}
              height={'36px'}
              width={'36px'}
              onClick={handleOpenUser}
              style={{ cursor: 'pointer' }}
            ></img>
          </Box>

          {
            <Box
              sx={{
                pl: { xs: '8px', sm: '8px', md: '12px', lg: '12px', xl: '12px' }
              }}
            >
              {estado !== '0' ? (
                <img
                  id="alertaImg"
                  src={
                    estado == '1' ? iconAmbar : estado == '2' ? iconRojo : ''
                  }
                  style={{ cursor: 'pointer' }}
                  onClick={handleClickAlerta}
                />
              ) : (
                ''
              )}
            </Box>
          }
        </Box>
      </Box>

      <DialogUser
        open={isOpen}
        handleClose={() => setOpen(false)}
        paper={{
          sx: {
            borderRadius: '10px',
            width: '360px',
            height: '336px',
            '&.MuiPaper-root': {
              margin: {
                xs: '80px 0px 0px 0px',
                sm: '80px 20px 0px 0px',
                md: '80px 20px 0px 0px',
                lg: '80px 20px 0px 0px',
                xl: '80px 20px 0px 0px'
              },
              boxShadow: '0px 0px 0px 0px'
            }
          }
        }}
        direction={'down'}
        contentOfDialog={
          <DialogContent
            sx={{
              padding: '20px 0px 0px 0px'
            }}
          >
            <Box padding={'0px 20px'}>
              <Box
                sx={{
                  backgroundColor: 'var(--primariomenta, #80CFBC)',
                  display: 'flex',
                  width: '320px',
                  height: '28px',
                  padding: '10px 20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  borderRadius: '14px'
                }}
              >
                <Typography
                  sx={{
                    overflow: 'hidden',
                    color: 'var(--neutralblanco, #FFF)',
                    textAlign: 'center',
                    textOverflow: 'ellipsis',
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '14px',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {response != null || response != undefined
                    ? response.empresa.name
                    : 'usuario'}
                </Typography>
              </Box>
            </Box>
            <Box pt={'20px'} pb={'20px'} textAlign={'center'}>
              <Box>
                <img src={fotoUser} style={{ paddingBottom: '8px' }} />
                <Typography
                  sx={{
                    overflow: 'hidden',
                    color: 'var(--boton-primario, #80CFBC)',
                    textAlign: 'center',
                    textOverflow: 'ellipsis',
                    /* DESKTOP/TITULOS/H5 */
                    fontFamily: 'Gotham',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px'
                  }}
                >
                  {usuario != null || usuario != undefined
                    ? usuario
                    : 'usuario'}
                </Typography>
                <Typography
                  sx={{
                    pt: '4px',
                    color: 'var(--neutralneutro, #88919E)',
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '16px'
                  }}
                >
                  {response != null || response != undefined
                    ? rol_user
                    : 'usuario'}
                </Typography>
                <Typography
                  sx={{
                    pt: '4px',
                    color: 'var(--neutralneutro, #88919E)',
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: '16px'
                  }}
                >
                  {response != null || response != undefined
                    ? response.username
                    : 'usuario'}
                </Typography>
              </Box>
            </Box>
            {/* {response != null || response != undefined ? response.certificado.fechavencimiento : 'usuario'} */}
            <Box
              display={'flex'}
              sx={{ p: '0px 20px', height: '48px', alignItems: 'center' }}
            >
              <Box display={'flex'}>
                <Box sx={{ p: '6px' }}>
                  <Brightness1Icon
                    sx={{
                      height: '12px',
                      width: '12px',
                      color:
                        response.certificado.estado == '0'
                          ? 'var(--color-marca-verde-800, #42C67E)'
                          : estado == '1'
                          ? 'var(--color-marca-mostaza-600, #F5A458)'
                          : 'var(--color-marca-rojo-800, #FF655A)'
                    }}
                  ></Brightness1Icon>
                </Box>
                <Box sx={{ alignSelf: 'center' }}>
                  <Typography
                    sx={{
                      color: 'var(--color-neutral-texto, #1E2947)',
                      /* MOVIL/CUERPO/PARRAFO B */
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    }}
                  >
                    Certificado{' '}
                    {estado == '0'
                      ? 'vigente hasta '
                      : estado == '1'
                      ? 'por vencer el '
                      : 'vencido el '}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ marginLeft: 'auto' }}>
                {estado == '0' ? ( //ESTADO VIGENTE (VERDE)
                  <EstadoPill
                    title={response.certificado.fechavencimiento.toString()}
                    bg="var(--color-marca-verde-200, #E7F9EF)"
                    color="#3CB473"
                  ></EstadoPill>
                ) : estado == '1' ? ( // ESTADO PROXIMO (AMBAR)
                  <EstadoPill
                    title={response.certificado.fechavencimiento.toString()}
                    bg="var(--color-marca-mostaza-200, #FFF5F0)"
                    color="#FFA826"
                  ></EstadoPill>
                ) : estado == '2' ? ( // ESTADO CRITICO (ROJO)
                  <EstadoPill
                    title={response.certificado.fechavencimiento.toString()}
                    bg="var(--color-marca-rojo-200, #FFECEB)"
                    color="#FF655A"
                  ></EstadoPill>
                ) : (
                  ''
                )}
              </Box>
            </Box>
            <Button
              onClick={handleopenSesion}
              onMouseEnter={() => setlogout(true)}
              onMouseLeave={() => setlogout(false)}
              sx={{
                background: 'var(--pastelmenta, #E8F6F3)',
                display: 'flex',
                alignItems: 'center',
                height: '48px',
                width: '100%',
                justifyContent: 'left',
                padding: '10px 20px',
                cursor: 'pointer',
                bottom: 0,
                position: 'absolute',
                ':hover': {
                  backgroundColor: logout
                    ? 'var(--secundarioceleste, #69C4DE)'
                    : 'var(--pastelmenta, #E8F6F3)'
                }
              }}
            >
              <Box display={'flex'} pr={'20px'}>
                <img src={logout ? logOutIconAA : logOutIcon} />
              </Box>
              <Typography
                sx={{
                  color: logout ? '#fff' : 'var(--neutralneutro, #88919E)',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px'
                }}
              >
                Cerrar Sesión
              </Typography>
            </Button>
          </DialogContent>
        }
      />
      {/*  log out modal */}

      <DialogScreen
        open={openModal}
        handleOpen={() => setOpenModal(true)}
        handleClose={() => setOpenModal(false)}
        paper={{
          sx: {
            borderRadius: '20px',
            minWidth: {
              xs: '320px',
              sm: '320px',
              md: '480px',
              lg: '480px',
              xl: '480px'
            },
            maxWidth: {
              xs: '320px',
              sm: '320px',
              md: '480px',
              lg: '480px',
              xl: '480px'
            },
            minHeight: {
              xs: '290px',
              sm: '290px',
              md: '402px',
              lg: '402px',
              xl: '402px'
            },
            maxHeight: {
              xs: '290px',
              sm: '290px',
              md: '402px',
              lg: '402px',
              xl: '402px'
            }
          }
        }}
        direction={'down'}
        contentOfDialog={
          <DialogContent
            sx={{
              padding: {
                xs: '24px 20px',
                sm: '24px 20px',
                md: '28px',
                lg: '28px',
                xl: '28px'
              }
            }}
          >
            <Box
              sx={{
                // width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: {
                  xs: '20px',
                  sm: '20px',
                  md: '24px',
                  lg: '24px',
                  xl: '24px'
                }
              }}
            >
              <Box
                component="img"
                src={imagenerror}
                sx={{
                  width: {
                    xs: '120px',
                    sm: '120px',
                    md: '200px',
                    lg: '200px',
                    xl: '200px'
                  },
                  height: {
                    xs: '120px',
                    sm: '120px',
                    md: '200px',
                    lg: '200px',
                    xl: '200px'
                  }
                }}
              ></Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  padding: '0px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  fontFamily: 'Gotham',
                  fontWeight: 400,
                  fontSize: {
                    xs: '18px',
                    sm: '18px',
                    md: '24px',
                    lg: '24px',
                    xl: '24px'
                  },
                  lineHeight: {
                    xs: '22px',
                    sm: '22px',
                    md: '26px',
                    lg: '26px',
                    xl: '26px'
                  },
                  textAlign: 'center',
                  color: 'var(--colorSecondary-Blue)',
                  paddingBottom: {
                    xs: '32px',
                    sm: '32px',
                    md: '40px',
                    lg: '40px',
                    xl: '40px'
                  }
                }}
              >
                ¿Deseas terminar tu sesión?
              </Typography>
            </Box>
            <Box>
              <Box display={'flex'}>
                <Box
                  width={'100%'}
                  sx={{
                    pr: {
                      xs: '8px',
                      sm: '8px',
                      md: '16px',
                      lg: '16px',
                      xl: '16px'
                    }
                  }}
                >
                  <ColorButtonStyleModal
                    handleEvent={handleCerrarSesion}
                    title="SI, SALIR"
                    pasive={true}
                  />
                </Box>
                <Box width={'100%'}>
                  <ColorButtonStyleModal
                    handleEvent={() => setOpenModal(false)}
                    title="CANCELAR"
                    pasive={false}
                  />
                </Box>
              </Box>
            </Box>
          </DialogContent>
        }
      />

      {/* dialog CERTIFICADO DIGITAL */}

      <ModalAlerta
        id="ringtone-menu"
        keepMounted
        open={openAlerta}
        onClose={() => setOpenAlerta(false)}
        value={value}
        estado={estado}
        fVencimiento={response.certificado.fechavencimiento}
        paper={{
          sx: {
            borderRadius: '8px 0px 0px 8px',
            // minWidth: { xs: '320px', sm: '320px', md: '480px', lg: '480px', xl: '480px' },
            // maxWidth: { xs: '320px', sm: '320px', md: '480px', lg: '480px', xl: '480px' },
            // minHeight: { xs: '290px', sm: '290px', md: '402px', lg: '402px', xl: '402px' },
            // maxHeight: { xs: '290px', sm: '290px', md: '402px', lg: '402px', xl: '402px' },
            margin: '0px',
            marginTop: '60px',
            boxShadow: 0
          }
        }}
      />
    </>
  );
};

export default HeaderMenu;
