import axios from 'axios';
import { CONFIG } from './configuracion.service';
import { Template } from 'src/models/templatesInterface';

const url = CONFIG.url + CONFIG.versionApi;

// export const getLocalesAll = async (token: string) => {
//   try {
//     const response = await axios.get<Locales>(`${url}/Locales/all`, {
//       headers: {
//         Authorization: 'Bearer ' + token
//       }
//     });

//     return response;
//   } catch (error: any) {
//     const response = error.response;
//     console.log('getLocales-BYinfomatica', response);
//     return response;
//   }
// };

export const getTemplatesAll = async () => {
  try {
    const locales = [
      {
        activo: true,
        description: 'Resumen de Compras totales por meseros',
        lastseen: 'Hace 1 Mes',
        idlocal: 'EBC0A2EC-7E6A-4C5B-9822-001F200000001',
        template: 'Compras por Meseros',
        token: 'KnsgjvO3iw********',
        urlLogo:
          'https://infofact.s3.amazonaws.com/logosinfofact/quinoa_20551585507.png'
      },
      {
        activo: true,
        description: 'Resumen de Ventas totales por meseros',
        lastseen: 'Hace 20 Meses',
        idlocal: 'EBC0A2EC-7E6A-4C5B-9822-001F200000002',
        template: 'Venta por Meseros',
        token: 'KnsgjvO3iw********',
        urlLogo:
          'https://infofact.s3.amazonaws.com/logosinfofact/quinoa_20551585507.png'
      }
    ];

    console.log(locales);
    console.log('####');
    return { data: locales };
  } catch (error: any) {
    console.log('getLocales-BYinfomatica', error);
    return { data: [] };
  }
};

export const postModificaLocal = async (
  iddocumento: string,
  activa: number,
  token: string
) => {
  try {
    const response = await axios.post<Template>(
      `${url}/Locales/Modifica/${iddocumento}/${activa}`,
      '',
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      }
    );
    return response;
  } catch (error: any) {
    const response = error.response;
    console.log('postbajaLocal', response);
    return response;
  }
};
