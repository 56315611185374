import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import 'dayjs/locale/es';
import { saveAs } from 'file-saver';
import CSS from 'csstype';
import { ReactComponent as CustomEditIcon } from '../../../../../assets/icons/BOTONES/EDIT-TABLE.svg';
import Checkbox from '@mui/material/Checkbox';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Box, IconButton, Paper, SelectChangeEvent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TokenContext } from 'src/contexts/UserContext';
import EnhancedTableHead from './EnhancedTableHead';
import ButtonLimpiar from '../../ColorButton/ButtonLimpiar';
import { Template } from 'src/models/templatesInterface';
import { postModificaLocal } from 'src/app/services/pedidos_consulta.service';
import DialogEditarPlantilla from '../../Dialog/DialogEditarPlantilla';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TableCellColumn: CSS.Properties = {
  padding: '0px 10px',
  height: '24px',
  textTransform: 'capitalize',
  fontWeight: 300,
  fontSize: '14px',
  lineHeight: '18px'
};

type Order = 'asc' | 'desc';

interface Props {
  datos: any[];
  page: number;
  rowsPerPage: any;
  setCantPage: (newValue: number) => void;
  headCells: any;
  menu: string;
  selectedCount: number;
  setSelectedCount: (newValue: number) => void;
  filtros?: any[];
  searchTerm?: any;
  selectSearch?: any;
  options?: any;
  setcantiFiltrado?: (newValue: number) => void;
  handleLimpia?: any;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const TablaPedidos = ({
  datos,
  page,
  rowsPerPage,
  setCantPage,
  headCells,
  menu,
  setSelectedCount,
  filtros,
  searchTerm,
  selectSearch,
  options,
  setcantiFiltrado,
  handleLimpia
}: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%'
      },
      paper: {
        width: '100%',
        marginBottom: theme.spacing(1)
      },
      table: {
        minWidth: '50px !important',
        marginBottom: theme.spacing(1)
      },

      tableCell: {
        padding: '0px',
        height: '10px',
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center'
      },
      tablerow: {},

      visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 10,
        width: 1
      },
      imageIcon: {
        height: '24px'
      },
      iconRoot: {
        textAlign: 'center'
      },

      card: {}
    })
  );

  const classes = useStyles();
  const { token, idToken, idRuta, idResponseLogin, loading, idLoading } =
    useContext(TokenContext);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Template>('idlocal');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  let rows = datos;
  const navigate = useNavigate();
  const [pageDet, setPageDet] = useState(0);

  // Abrir modal de Editar Plantilla
  const [openConfirma, setOpenConfirma] = useState(false);
  const [confirmacion, setConfirmacion] = useState('');
  const [tituloPlantilla, setTituloPlantilla] = useState('');
  const [descripcionPlantilla, setDescripcionPlantilla] = useState('');

  const handleChangePageDet = (event: unknown, newPage: number) => {
    setPageDet(newPage - 1);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Template
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const descargaLinkSunat = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  function descargarArchivo(
    base64String: string,
    nombreArchivo: string,
    tipo: string
  ) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: tipo });
    saveAs(blob, nombreArchivo);
  }

  interface EnhancedTableToolbarProps {
    numSelected: number;
  }
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedCount(newSelected.length);
      return;
    }
    setSelected([]);
    setSelectedCount(0);
  };

  const handleModification = async (codigolocal, positionIndex, activo) => {
    try {
      // console.log('BAJAR LOCAL')
      if (
        confirmacion.length == 14 &&
        confirmacion.toUpperCase() == 'ESTOY CONFORME'
      ) {
        idLoading(true);
        const modificalocal = await postModificaLocal(
          codigolocal,
          activo,
          token
        );
        if (modificalocal.status === 200 && modificalocal.data !== undefined) {
          idLoading(false);
          setOpenConfirma(false);
          setConfirmacion('');
          window.location.reload();
        }
      }
    } catch (error: any) {
      const response = error.message;
      console.log('fetchBaja LOCAL', response);
    }
  };

  const getCabecera = options.find((f: any) => f.value === selectSearch);

  const filteredRecords = rows.filter((row: any) =>
    Object.values(row).some((field: any) =>
      field
        .toString()
        .toLowerCase()
        .includes(searchTerm.toString().toLowerCase())
    )
  );

  setCantPage(Math.ceil(filteredRecords.length / rowsPerPage));

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const handlePreviewModalConfirmation = (
    index: number,
    template: string,
    description: string
  ) => {
    setOpenConfirma(true);
    setDescripcionPlantilla(description);
    setTituloPlantilla(template);

    console.log(template);
    console.log(description);
    console.log(index);
  };

  const handleClick = (index: any) => {
    // Acción que deseas realizar al hacer clic en la fila
    console.log(`Fila clickeada: ${index}`);
    //
    navigate('/menu/correlativos/pedidos/consulta');
  };

  function applySortFilter(array: any, comparator: any, query: any) {
    const stabilizedThis = array.map((el: any, index: any) => [el, index]);
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filteredRecords;
    }
    return stabilizedThis.map((el: any) => el[0]);
  }
  const filtrado = applySortFilter(
    rows,
    getComparator(order, orderBy),
    searchTerm
  );

  setcantiFiltrado(filteredRecords.length);

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { name, value } = event.target;
  };
  const limpiaConfirmaModal = () => {
    setConfirmacion('');
  };

  return (
    <>
      <React.Fragment>
        <div className={classes.root} style={{}}>
          {filteredRecords.length > 0 ? (
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer
                sx={{
                  maxHeight: { xs: 200, sm: 300, md: 400, lg: 500, xl: 500 },
                  '::-webkit-scrollbar': {
                    width: '20px',
                    height: '20px',
                    border: '2px solid #000000da'
                  },
                  '::-webkit-scrollbar-track': {
                    display: 'flex',
                    background: 'var(--pastelmenta, #E8F6F3)'
                  },
                  '::-webkit-scrollbar-thumb': {
                    backgroundColor: 'var(--neutralhover, #A7B1BF)',
                    borderRadius: '20px',
                    border: '6px solid var(--pastelmenta, #E8F6F3)'
                  },
                  '::-webkit-scrollbar-thumb:hover': {
                    border: '5px solid var(--pastelmenta, #E8F6F3)'
                  },
                  '::-webkit-scrollbar-corner': {
                    background: 'none'
                  }
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCellsp={headCells}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={rows.length}
                    numSelected={selected.length}
                  />
                  <TableBody>
                    {stableSort(filtrado, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any, index: number) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={index}
                            key={index}
                            sx={{
                              height: '24px',
                              '&:hover': {
                                backgroundColor: `#F0F9F7`,
                                '& .MuiTableCell-root': {
                                  '&.columnaResaltada': {
                                    cursor: 'pointer'
                                  }
                                }
                              }
                            }}
                          >
                            <TableCell
                              scope="row"
                              style={TableCellColumn}
                              sx={{
                                textAlign: 'left',
                                cursor: 'pointer',
                                '&:hover': {
                                  color: '#3677DE',
                                  textDecoration: 'underline'
                                }
                              }}
                              onClick={() => handleClick(row.idlocal)}
                            >
                              <Checkbox
                                sx={{
                                  color: '#3677DE'
                                }}
                              />
                              {row.template}
                            </TableCell>
                            <TableCell style={TableCellColumn}>
                              {row.description}{' '}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: 0
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  width: '132px',
                                  height: '24px',
                                  padding: '10px 12px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  gap: '4px',
                                  borderRadius: '20px',
                                  background: 'var(--boton-200-pastel, #EDF4FD)'
                                }}
                              >
                                <span
                                  style={{
                                    color:
                                      'var(--texto-900-contraste, #2664C7)',
                                    textAlign: 'center',
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '14px'
                                  }}
                                >
                                  {row.lastseen}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell>
                              {/* br */}
                              <IconButton
                                sx={{
                                  height: '24px'
                                }}
                                onClick={() =>
                                  handlePreviewModalConfirmation(
                                    index,
                                    row.template,
                                    row.description
                                  )
                                }
                              >
                                <CustomEditIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <Box display={'flex'} height={'24px'} alignItems={'center'}>
              <Box width={'240px'}>
                <Typography
                  sx={{
                    color: 'var(--neutralnegro, #232323)',
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '16px'
                  }}
                >
                  No se encontraron registros
                </Typography>
              </Box>
              <Box>
                <ButtonLimpiar
                  handleEvent={handleLimpia}
                  title={'Limpiar'}
                  pasive={false}
                />
              </Box>
            </Box>
          )}
        </div>
      </React.Fragment>

      {/* --Modal para edición de Plantillas */}
      <DialogEditarPlantilla
        open={openConfirma}
        data={{ description: descripcionPlantilla, titulo: tituloPlantilla }}
        handleClose={() => setOpenConfirma(false)}
        paper={{
          sx: {
            height: '400px',
            minHeight: '400px',
            alignItems: 'flex-start',
            gap: '20px',
            borderRadius: '12px',
            background: '#FFF'
          }
        }}
        direction={'down'}
      />
    </>
  );
};

export default TablaPedidos;
