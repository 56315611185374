import React, { useState } from 'react';
import { Typography, Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  height: string;
  title: string;
  label: string;
  titleInput: string;
  valueInput?: string;
  nameValue?: string;
  handleChangeInputValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickShowLogin?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleMouseDownLogin?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  showLogin?: boolean;
  error?: boolean;
  type: string;
  required: boolean;
  maxLength?: string;
  minLength?: string;
  value: any;
  activeFocus?: any;
  length?: number;
  activoOculto?: any;
  activoVisible?: any;
  imgActivo?: any;
  imgPasivo?: any;
  claveActivo?: any;
  clavePasivo?: any;
  mensajeError?: any;
  multiline?: boolean;
}

const TextFieldNuevoTablero: React.FC<Props> = ({
  height,
  label,
  titleInput,
  nameValue,
  handleChangeInputValues,
  error,
  type,
  maxLength,
  minLength,
  value,
  multiline,
  mensajeError
}: Props) => {
  const useStyles = makeStyles((theme: any) => ({
    input: {
      fontSize: '12px',
      width: '100%',
      '& .Mui-error': {
        paddingLeft: '20px',
        borderRadius: '10px'
      },
      '& .MuiFormHelperText-root': {
        color: 'var(--colorSecondary-Blue)',
        height: { xs: '48px', sm: '48px', md: '56px', lg: '56px', xl: '56px' }
      },

      '& .MuiInput-root': {
        color: 'var(--colorSecondary-Blue)',
        height: { xs: '48px', sm: '48px', md: '56px', lg: '56px', xl: '56px' },

        marginTop: '-50px',
        marginLeft: '10px',
        marginRight: '10px'
      },
      '& .MuiInputBase-root': {
        height: { xs: '48px', sm: '48px', md: '56px', lg: '56px', xl: '56px' },
        boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.12)',
        marginTop: '-50px',
        marginLeft: '10px',
        marginRight: '10px',
        border: '1px',

        color: '#88919E',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: '20px',

        paddingLeft: '10px',
        '& ::placeholder': {
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 300,
          fontSize: '12px',
          lineHeight: '16px',
          color: '#959595',
          height: { xs: '48px', sm: '48px', md: '56px', lg: '56px', xl: '56px' }
        }
      }
    }
  }));

  return (
    <Box
      sx={{
        // height: '100px',

        height: { xs: '48px', sm: height, md: height, lg: height, xl: height },
        width: '100%'
        // width: {
        //   xl: '400px',
        //   lg: '400px',
        //   md: '400px',
        //   sm: '320px',
        //   xs: '320px'
        // }
      }}
    >
      <TextField
        multiline={multiline}
        rows={2}
        maxRows={4}
        variant="standard"
        label={label}
        placeholder={titleInput}
        type={type}
        name={nameValue}
        value={value}
        onChange={handleChangeInputValues}
        {...(error && { error: true, helperText: error })}
        InputProps={{
          // startAdornment: <AccountCircle />, // <== adjusted this
          disableUnderline: true // <== added this
        }}
        inputProps={{
          maxLength: maxLength,
          minLength: minLength
        }}
        // onFocus={handleFocusLogin}
        // onBlur={handleBlurLogin}
        sx={{
          width: '100%',
          paddingBottom: '0px',
          '&.MuiFormControl-root': {
            borderRadius: '4px',
            height: {
              xs: '48px',
              sm: height,
              md: height,
              lg: height,
              xl: height
            },
            boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.12)',
            border: '1px solid var(--neutralgrisclaro, #E2E5E8)',
            ':hover': {
              boxShadow: '0px 0px 0px 0px rgba(25, 67, 127, 0.12)',
              backgroundColor: 'var(--colorHoverSecondary-Green)',
              border: '1px solid var(--colorHoverText-Green)'
            }
          },
          '& .MuiFormLabel-root': {
            marginTop: '-6px',
            paddingTop: value !== '' ? '20px' : '0px',
            paddingLeft: '14px',
            fontFamily: 'Poppins',
            fontWeight: value !== '' ? 500 : 300,
            fontSize: {
              xs: '14px',
              sm: '14px',
              md: '16px',
              lg: '16px',
              xl: '16px'
            },
            lineHeight: {
              xs: '20px',
              sm: '20px',
              md: '24px',
              lg: '24px',
              xl: '24px'
            },
            color:
              value !== ''
                ? 'var(--colorPrimary-Black)'
                : 'var(--colorPrimary-Gray)',
            '&.Mui-focused': {
              fontSize: {
                xs: '14px',
                sm: '14px',
                md: '16px',
                lg: '16px',
                xl: '16px'
              },
              lineHeight: {
                xs: '14px',
                sm: '14px',
                md: '18px',
                lg: '18px',
                xl: '18px'
              },
              color: 'var(--colorPrimary-Black)',
              fontFamily: 'Poppins',
              fontWeight: 500,
              paddingLeft: '14px',
              paddingTop: value !== '' ? '23px' : '23px'
            }
          },
          '& .MuiInputBase-root': {
            height: '100%',
            fontSize: {
              xs: '12px',
              sm: '12px',
              md: '14px',
              lg: '14px',
              xl: '14px'
            },
            lineHeight: {
              xs: '14px',
              sm: '14px',
              md: '18px',
              lg: '18px',
              xl: '18px'
            },
            color: 'var(--colorPrimary-Black)',
            fontFamily: 'Poppins',
            fontWeight: 300,
            marginTop: '0px',
            paddingTop: value !== '' ? '20px' : '20px',
            paddingLeft: '12px',
            '& ::placeholder': {
              fontFamily: 'Poppins',
              fontWeight: 300,
              fontSize: {
                xs: '14px',
                sm: '14px',
                md: '16px',
                lg: '16px',
                xl: '16px'
              },
              lineHeight: {
                xs: '20px',
                sm: '20px',
                md: '24px',
                lg: '24px',
                xl: '24px'
              },
              color: 'var(--colorPrimary-Gray)'
            }
          }
        }}
      />
      <Typography
        fontFamily={'Poppins'}
        fontSize={'12px'}
        fontWeight={500}
        alignItems="center"
        sx={{
          color: 'var(--colorPrimary-Error)',
          display: mensajeError !== '' ? 'flex' : 'none',
          //   width: { xs: '320px', sm: '400px', md: '460px', lg: '460px' },
          fontSize: '12px',
          fontFamily: 'Poppins',
          fontWeight: '500',
          lineHeight: {
            xs: '16px',
            sm: '16px',
            md: '18px',
            lg: '18px',
            xl: '18px'
          },
          marginTop: '5px'
        }}
      ></Typography>
    </Box>
  );
};

export default TextFieldNuevoTablero;
// export default InputAndTitle
