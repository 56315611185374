// MUI - REACT
import { Helmet } from 'react-helmet-async';
import 'dayjs/locale/es';
import {
  Container,
  Grid,
  Pagination,
  SelectChangeEvent,
  Typography,
  useTheme
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { useContext, useEffect, useState } from 'react';

// SERVICES
import { getTemplatesAll } from 'src/app/services/pedidos_consulta.service';

// SVG - IMAGES
import Listaicon from '../../../assets/icons/SIDEBAR/Plantillas-ACTIVE.svg';
import poweredBy from '../../../assets/icons/POWERED BY_131x28.svg';
import { ReactComponent as DOC_BY } from '../../../assets/icons/BOTONES/DOC_BI.svg';

// COMPONENTES - INTERFACES
import TablaPedidos from 'src/content/pages/Components/Table/Template/TablaPedidos';
import TextBuscador from '../../../components/TextField/TextBuscador';
import { TokenContext } from 'src/contexts/UserContext';
import { Template } from 'src/models/templatesInterface';

const AdmPedidos = () => {
  // DATA CRUDA
  const options = [
    {
      descripcion: 'TODOS',
      value: 0,
      cabecera: 'receptor'
    },
    {
      descripcion: 'Plantilla',
      value: 1,
      cabecera: 'rucreceptor'
    }
  ];

  const headCells: any = [
    {
      id: 'idlocal',
      numeric: false,
      disablePadding: false,
      label: 'ID LOCAL',
      width: '540px',
      widthXS: '420px',
      left: '0px 10px',
      visible: false
    },
    {
      id: 'template',
      numeric: true,
      disablePadding: true,
      label: 'Plantilla',
      width: '280px',
      widthXS: '100px',
      left: '0px 10px',
      visible: true
    },
    {
      id: 'description',
      numeric: true,
      disablePadding: true,
      label: 'Descripción',
      width: '280px',
      widthXS: '120px',
      left: '0px 10px',
      visible: true
    },
    {
      id: 'lastseen',
      numeric: true,
      disablePadding: false,
      label: 'Última Vista',
      width: '142px',
      widthXS: '112px',
      left: '0px 10px',
      visible: true
    },
    {
      id: 'edit',
      numeric: true,
      disablePadding: true,
      label: 'Editar',
      width: '46px',
      widthXS: '46px',
      left: '0px 10px',
      visible: true
    }
  ];

  const { idLoading } = useContext(TokenContext);
  const [filtros, setfiltros] = useState([]);
  const [pedidosArray, setPedidosArray] = useState<Template[]>([]);
  const [page, setPage] = useState(0);
  const [valuefilas, setvalueFilas] = useState(15);
  const [cantPage, setCantPage] = useState(0);
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [cantFiltrado, setcantiFiltrado] = useState(0);
  const [valueTextSearch, setvalueTextSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [valueSearch, setValueSearch] = useState(0);

  const theme = useTheme();

  // Validación de carga de data
  const fetchLocalesAll = async () => {
    try {
      idLoading(true);
      setPedidosArray([]);
      const PedidosConsulta = await getTemplatesAll();
      if (PedidosConsulta !== undefined) {
        setPedidosArray(PedidosConsulta.data);
        idLoading(false);
      }
    } catch (error: any) {
      setPedidosArray([]);
      const response = error.message;
    }
  };
  useEffect(() => {
    fetchLocalesAll();
  }, []);

  // Limpiar datos de tabla
  const handleLimpiarTodo = () => {
    setfiltros([]);
    setSearchTerm('');
    setvalueTextSearch('');
  };
  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'buscador') {
      setvalueTextSearch(value);
      setPage(0);
    }
    setSearchTerm(value);
    // console.log('sssss¿', value, searchTerm)
  };

  // Buscador por palabras
  const handleChangeSelect = (event: SelectChangeEvent) => {
    setValueSearch(event.target.value as any);
  };

  // Cambio de Paginas de la tabla
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  return (
    <>
      <Helmet>
        <title>Correlativos - BI</title>
      </Helmet>

      <Container
        sx={{
          paddingTop: {
            xs: '84px',
            sm: '84px',
            md: '88px',
            lg: '88px',
            xl: '88px'
          },

          '&.MuiContainer-root': {
            paddingLeft: '20px',
            paddingRight: '20px'
          },
          maxWidth: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '100%',
            xl: '100%'
          }
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px', // 150%

            color: theme.colors.info.light,
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontStyle: 'normal'
          }}
        >
          Correlativos
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Gotham',
            fontWeight: 400,
            fontSize: '24px',
            lineHeight: '28px', // 116.667%
            color: theme.colors.primary.main,
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontStyle: 'normal'
          }}
        >
          Consulta de pedidos
        </Typography>
      </Container>

      <Container
        sx={{
          paddingTop: '10px',
          '&.MuiContainer-root': {
            paddingLeft: '20px',
            paddingRight: '20px',
            pb: '20px'
          },
          maxWidth: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '100%',
            xl: '100%'
          }
        }}
      >
        {
          <>
            <Box
              sx={{
                display: {
                  xs: 'block',
                  sm: 'block',
                  md: 'block',
                  lgx: 'flex',
                  lg: 'flex',
                  xl: 'flex'
                }
              }}
            >
              <Box
                sx={{
                  marginBlock: 1,
                  borderRadius: '8px',
                  paddingBottom: '12px',
                  width: '100%'
                }}
              >
                <Typography
                  sx={{
                    color: theme.colors.success.main,
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '18px'
                  }}
                >
                  {
                    <>
                      <Box display={'flex'} alignItems={'center'}>
                        <Box display={'flex'} alignItems={'center'} pr={'20px'}>
                          <Box display={'flex'} pr={'12px'}>
                            <img src={Listaicon}></img>
                          </Box>

                          <Typography
                            sx={{
                              color: theme.colors.success.main,
                              fontFamily: 'Poppins',
                              fontSize: {
                                xs: '14px',
                                sm: '14px',
                                md: '16px',
                                lg: '16px',
                                xl: '16px'
                              },
                              fontStyle: 'normal',
                              fontWeight: 500,
                              lineHeight: '18px'
                            }}
                          >
                            Mis plantillas creadas
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            width: '100px',
                            borderRadius: '20px',
                            background: 'var(--pastelmenta, #E8F6F3)',
                            display: 'flex',
                            height: '24px',
                            // padding: '10px 12px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px'
                          }}
                        >
                          {' '}
                          <Box>
                            <Typography
                              color="primary"
                              sx={{
                                textAlign: 'center',
                                fontFamily: 'Poppins',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '14px'
                              }}
                            >
                              {cantFiltrado} registros
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  }
                </Typography>
              </Box>

              <Box display={'flex'} width={'100%'} justifyContent={'right'}>
                <Box
                  sx={{
                    display: { xs: 'column', md: 'flex' },
                    justifyContent: { xs: 'start', md: 'start', lg: 'right' }
                  }}
                  width={'100%'}
                >
                  <Box
                    sx={{
                      opacity: 1,
                      width: {
                        xl: '400px',
                        lg: '400px',
                        md: '400px',
                        sm: '100%'
                      },
                      display: {
                        xl: 'flex',
                        lg: 'flex',
                        md: 'flex',
                        sm: 'flex',
                        xs: 'none'
                      }
                    }}
                  >
                    <TextBuscador
                      pplaceholder="Buscar en la tabla"
                      value={valueTextSearch}
                      nameValue="buscador"
                      type={'text'}
                      valueSelect={valueSearch}
                      nameValueSelect="selectBuscar"
                      options={options}
                      handleChangeInputValues={handleChangeInput}
                      handleChangeSelect={handleChangeSelect}
                      required={true}
                    />
                  </Box>
                  <Button
                    sx={{
                      display: 'flex',
                      width: { xs: '100%', md: '256px' },
                      height: '48px',
                      padding: '12px 24px',
                      ml: { xs: '0px', md: '12px' },
                      marginY: { xs: '12px', md: '0px' },
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '12px',
                      color: '#FFFFFF',
                      textAlign: 'center',
                      borderRadius: '30px',
                      background: 'var(--boton-800-primario, #3677DE)',
                      boxShadow: '0px 2px 10px 0px rgba(30, 41, 71, 0.14)',
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '16px',
                      textTransform: 'uppercase',
                      '&:hover': {
                        borderRadius: '30px',
                        background: 'var(--secundarioceleste)',
                        boxShadow: '0px 4px 12px 0px rgba(30, 41, 71, 0.08)'
                      }
                    }}
                  >
                    <SvgIcon component={DOC_BY} fontSize="inherit" />
                    CREAR PLANTILLA
                  </Button>
                </Box>
              </Box>
            </Box>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  sx={{
                    border: '1px solid var(--hovermenta, #C0E7DE)',
                    borderRadius: '8px'
                  }}
                >
                  <TablaPedidos
                    datos={pedidosArray}
                    page={page}
                    rowsPerPage={valuefilas}
                    setCantPage={setCantPage}
                    headCells={headCells}
                    selectedCount={selectedCount}
                    setSelectedCount={setSelectedCount}
                    menu="cobranzas"
                    filtros={filtros}
                    searchTerm={searchTerm}
                    selectSearch={''}
                    options={options}
                    setcantiFiltrado={setcantiFiltrado}
                    handleLimpia={handleLimpiarTodo} // limpia todo
                  />
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                pt: '8px',
                width: '100%',
                display: {
                  xs: 'block',
                  sm: 'block',
                  md: 'flex',
                  lg: 'flex',
                  xl: 'flex'
                },
                justifyContent: 'center'
              }}
            >
              <Box
                sx={{
                  position: {
                    xs: '',
                    sm: '',
                    md: 'absolute',
                    lg: 'absolute',
                    xl: 'absolute'
                  },
                  // display: cantFiltrado !== 0 ? 'flex' : 'none',
                  justifyContent: 'center',
                  pb: { xs: '8px', sm: '8px', md: '0px', lg: '0px', xl: '0px' }
                }}
              >
                <Pagination
                  defaultPage={page + 1}
                  count={cantPage}
                  onChange={handleChangePage}
                  sx={{
                    '& .Mui-selected': {
                      color: '#000'
                    }
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  textAlign: {
                    xs: 'center',
                    sm: 'center',
                    md: 'right',
                    lg: 'right',
                    xl: 'right'
                  }
                }}
              >
                <img src={poweredBy} />
                {/* POWERED BY */}
              </Box>
            </Box>
          </>
        }
      </Container>
    </>
  );
};

export default AdmPedidos;
