import React, { useState } from 'react';
import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import Box from '@mui/material/Box/Box';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { SelectChangeEvent } from '@mui/material/Select';
import IconPasivo from '../../assets/icons/BUSQUEDA/BUSCAR MINI-PASIVO_18X18.svg';
import IconActivo from '../../assets/icons/BUSQUEDA/BUSCAR MINI-ACTIVO_18X18.svg';

interface Props {
  pplaceholder: string;
  value: string;
  nameValue: string;
  nameValueSelect: string;
  type: string;
  valueSelect: any;
  options: any;
  handleChangeInputValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSelect: (event: SelectChangeEvent) => void;
  required: boolean;
}

const TextBuscador: React.FC<Props> = ({
  pplaceholder,
  nameValue,
  type,
  value,
  handleChangeInputValues,
  handleChangeSelect,
  valueSelect,
  options,
  nameValueSelect
}: Props) => {
  const BootstrapInput = styled(InputBase)(({ theme }) => ({}));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          borderRadius: '4px',
          height: '48px',
          width: '100%',
          boxShadow: '0px 4px 8px 0px rgba(30, 41, 71, 0.08)',
          border: '1px solid var(--color-neutral-400, #E2E5E8)'
        }}
      >
        <Box>
          <FormControl
            variant="standard"
            style={{
              borderRadius: '4px 0px 0px 0px',
              borderRight: '1px solid var(--color-neutral-400, #E2E5E8)',
              color: '#000',
              width: '100%'
            }}
            // {...(error && { error: true })}
          >
            <Select
              displayEmpty
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              value={valueSelect}
              name={nameValueSelect}
              onChange={handleChangeSelect}
              input={<BootstrapInput />}
              sx={{
                backgroundColor: 'var(--colorSecundaryOrange)',
                display: 'flex',
                padding: '10px 12px',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'var(--boton-800-primario, #3677DE)',
                borderRadius: '8px 0px 0px 8px',
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
                height: '48px',
                ':hover': {
                  backgroundColor: 'var(--colorPrimary-Hover)'
                }
              }}
              inputProps={{
                MenuProps: {
                  MenuListProps: {
                    sx: {
                      paper: {
                        borderRadius: '10px'
                      },
                      borderRadius: '10px'
                    }
                  }
                }
              }}
            >
              {options.map((o: any, index: any) => (
                <MenuItem
                  key={index}
                  value={o.value}
                  sx={{
                    color: '#232323',
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: '20px'
                  }}
                >
                  {o.descripcion}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <TextField
          variant="standard"
          label={''}
          placeholder={pplaceholder}
          type={type}
          name={nameValue}
          value={value}
          onChange={handleChangeInputValues}
          InputProps={{
            disableUnderline: true, // <== added this
            endAdornment: (
              <img
                src={value.length > 0 ? IconActivo : IconPasivo}
                style={{ padding: '10px' }}
              />
            )
          }}
          sx={{
            width: '100%',
            height: '48px',
            fontFamily: 'Poppins',
            '&.MuiFormControl-root': {
              borderRadius: '0px 8px 8px 0px'
            },

            '& .MuiInputBase-root': {
              height: '48px',
              '& .MuiOutlinedInput-notchedOutline': {},
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontWeight: 300
            },
            '& .MuiInputBase-input': {
              height: '48px',
              textAlign: 'left',
              '.MuiOutlinedInput-input': {
                padding: '0px',
                height: '48px'
              },
              '& .MuiInput-input': {
                padding: '0px'
              }
            },
            '& .MuiInputBase-input.MuiInput-input': {
              padding: '0px 0px 0px 10px'
            }
          }}
        />
      </Box>
    </>
  );
};

export default TextBuscador;
