import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import IconConfig from '../../../../src/assets/icons/CONFIGURACION_24X24.svg';
import IconLogOut from '../../../../src/assets/icons/SIDEBAR/SIDEBAR CERRAR_52X48.svg';
import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  Button,
  lighten,
  darken,
  IconButton,
  Tooltip,
  Grid,
  Typography,
  DialogContent
} from '@mui/material';
import MenuIcon from '../../../../src/assets/icons/MENU VERDE_24X24.svg';
import SalirIcon from '../../../../src/assets/icons/CERRAR_24X24.svg';
import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/LogoSign';
import DialogScreen from 'src/content/pages/Components/Dialog';
import ColorButtonStyle from 'src/content/pages/Components/ColorButton/ColorButtonStyle';
import { TokenContext } from 'src/contexts/UserContext';
import ColorButtonStyleModal from 'src/content/pages/Components/ColorButton/ColorButtonStyleModal';
// import sbOpen from '../../../assets';
import sbOpen from '../../../assets/icons/SIDEBAR/SIDEBAR ABRIR_52X48.svg';
import sbClose from '../../../assets/icons/SIDEBAR/SIDEBAR CERRAR_52X48.svg';
import { ResponseLogin } from 'src/models/login';
import SidebarMenuGrouped from 'src/content/pages/Components/SidebarMenuGrouped/SidebarMenuGrouped';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const Sidebar = (props: Props) => {
  const { window } = props;
  const [isHovered, setIsHovered] = useState(false);

  const { idToken, idRuta, idResponseLogin } = useContext(TokenContext);
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const [openModal, setOpenModal] = useState(false);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCerrarSesion = () => {
    idRuta(undefined);
    idToken(undefined);
    idResponseLogin(undefined);

    // window.location.reload();
    // return navigate("/");
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [openSideBar, setOpenSidebar] = useState(false);
  const response: ResponseLogin = JSON.parse(
    localStorage.getItem('responseLogin')
  );

  return (
    <>
      <SidebarWrapper
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'inline-block',
            lg: 'inline-block',
            xl: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background: 'white',
          boxShadow: '4px 0px 10px 0px rgba(25, 67, 127, 0.12)'
        }}
      >
        <SidebarMenu response={response} />
        {isHovered && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              zIndex: 10,
              // Falta validar funcionalidad de 'transform' y 'transition'
              transition: 'transform 1s ease-out',
              transform: isHovered ? 'translateX(0)' : 'translateX(-100%)'
            }}
          >
            <SidebarMenuGrouped />
          </div>
        )}
      </SidebarWrapper>
      <Drawer
        sx={{
          display: {
            xs: 'flex',
            sm: 'flex',
            md: 'none',
            lg: 'none',
            xl: 'none'
          }
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background: 'red',
            paddingBottom: '52px',
            // width: '320px',
            boxShadow: ' 4px 0px 20px 0px rgba(25, 67, 127, 0.2)'
          }}
        >
          <Scrollbar>
            <SidebarMenu response={response} />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
};

export default Sidebar;
