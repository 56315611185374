import * as React from 'react';
import { styled, alpha, useTheme } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as CheckMenuToolMarcas } from '../../../../assets/icons/CheckMemuToolMarcas.svg';
import ArrowDropDownIcon from '../../../../assets/icons/BOTONES/ARROW_SIDENAV.svg';
import CHANGEDMARCA from '../../../../assets/icons/BOTONES/CHANGEDMARCA.svg';

const StyledMenuMarcas = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    padding: '0px',
    minWidth: 240,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0px'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

export default function CustomizedMenusMarcas({ marcas }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedBrand, setSelectedBrand] = React.useState<string | null>(
    localStorage.getItem('selectedBrand') || null
  );
  const theme = useTheme();
  const isLgx = useMediaQuery(theme.breakpoints.down('lgx'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectBrand = (brand: string) => {
    setSelectedBrand(brand);
    localStorage.setItem('selectedBrand', brand);
    handleClose();
  };

  return (
    <div>
      <Box
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          display: 'flex',
          padding: '12px 4px 12px 8px',
          '&:hover': {
            background: '#EDF4FD'
          },
          '&:active': {
            background: '#EDF4FD'
          }
        }}
      >
        <button
          onClick={handleClick}
          style={{
            border: 'none',
            padding: 0,
            background: 'none',
            cursor: 'pointer'
          }}
        >
          <img src={CHANGEDMARCA} alt="Editar" />{' '}
          <img src={ArrowDropDownIcon} alt="Flecha hacia abajo" />{' '}
        </button>
      </Box>

      <StyledMenuMarcas
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem sx={{ padding: 0 }} disableRipple>
          <Box
            sx={{
              display: 'flex',
              padding: '12px 0px 12px 64px',
              alignItems: 'center',
              gap: '12px',
              width: '176px',
              alignSelf: 'stretch'
            }}
          >
            <Typography
              color="primary"
              sx={{
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px'
              }}
            >
              Marcas
            </Typography>
          </Box>
        </MenuItem>
        {marcas.map((brand) => (
          <MenuItem
            key={brand}
            sx={{ padding: 0 }}
            onClick={() => handleSelectBrand(brand)}
            disableRipple
          >
            <Box
              sx={{
                display: 'flex',
                padding: '10px 20px',
                alignItems: 'center',
                gap: '20px',
                alignSelf: 'stretch'
              }}
            >
              {selectedBrand === brand ? (
                <CheckMenuToolMarcas
                  style={{ width: '24px', height: '24px' }}
                />
              ) : (
                <Box sx={{ width: '24px', height: '24px' }} />
              )}
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  lineHeight: '16px',
                  color:
                    selectedBrand === brand
                      ? theme.palette.primary.main
                      : '#717883',
                  '&:hover': {
                    color: theme.palette.success.main
                  }
                }}
              >
                {brand}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </StyledMenuMarcas>
    </div>
  );
}
