import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton
} from '@mui/material';

import FlechaHaciaAbajoSIDENAV from '../../../../../assets/icons/SIDEBAR/FlechaHaciaAbajoSIDENAV.svg';
import { useNavigate } from 'react-router-dom';
import FlechaHorizontalSIDENAV from '../../../../../assets/icons/SIDEBAR/FlechaHorizontalSIDENAV.svg';
import CheckCLIENTE from '../../../../../assets/icons/SIDEBAR/CheckCLIENTE.svg';
import AbajoCLIENTE from '../../../../../assets/icons/SIDEBAR/AbajoCLIENTE.svg';

const SelectedListItem = ({ menuData }) => {
  const [openItems, setOpenItems] = useState({});
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [selectedChanges, setSelectedChanges] = useState({});

  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    const storedItem = localStorage.getItem('selectedMenuItem');
    if (storedItem) {
      setSelectedItem(JSON.parse(storedItem));
    }
  }, []);

  // Cambiar los iconos al desplegar el menú
  const handleChangedIcon = (key) => {
    setSelectedChanges((prevSelectedChanges) => ({
      ...prevSelectedChanges,
      [key]: !prevSelectedChanges[key]
    }));
    console.log(selectedChanges[key]); // Esto imprimirá el valor anterior debido a la asincronía de setState
  };

  const handleToggle = (key) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [key]: !prevOpenItems[key]
    }));
  };

  const handleNavigation = (rutaPrincipal) => {
    localStorage.setItem('ruta', JSON.stringify(rutaPrincipal));
    if (rutaPrincipal) {
      navigate(rutaPrincipal);
    }
  };

  const handleItemSelect = (title) => {
    // Store selected item in localStorage
    localStorage.setItem('selectedMenuItem', JSON.stringify(title));
    setSelectedItem(title);
  };

  const renderSubitems = (subitems, parentKey, depth = 1) => {
    return (
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          paddingX: 0,
          paddingY: 0
        }}
      >
        {subitems.map((item, index) => {
          const key = `${parentKey}-${index}`;
          const isSelected = selectedItem === item.title; // Verifica si el item actual está seleccionado
          const isHovered = hoveredItem === key;
          const hasMultipleSubitems = item.subitems && item.subitems.length > 1;

          return (
            <React.Fragment key={key}>
              <ListItem
                button
                onClick={(event) => {
                  handleToggle(key);
                  handleChangedIcon(key);
                  handleNavigation(item.rutaPrincipal);
                  if (item.status === true) {
                    handleItemSelect(item.title);
                  }
                }}
                onMouseEnter={() => setHoveredItem(key)}
                onMouseLeave={() => setHoveredItem(null)}
                sx={{
                  display: 'flex',
                  height: '44px',
                  padding: '0px 20px 0px 28px',
                  alignItems: 'center',
                  gap: '20px',
                  alignSelf: 'stretch',
                  backgroundColor: isSelected ? '#f0f0f0' : 'transparent' // Cambia el color de fondo si está seleccionado
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    pl: item.indice === 2 ? 1 : 0,
                    flex: '1 0 0',
                    color:
                      isSelected || isHovered
                        ? '#3677DE'
                        : 'var(--color-neutral-texto, #1E2947)',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    // Aplica un estilo condicional basado en isSelected
                    ...(isSelected && { color: '#3677DE' })
                  }}
                >
                  {item.title}
                </Typography>
                {hasMultipleSubitems &&
                  item.icon &&
                  (selectedChanges[key] ? (
                    <img src={CheckCLIENTE} alt={item.title} />
                  ) : (
                    <img src={item.icon} alt={item.title} />
                  ))}
                {depth === 2 && isHovered && (
                  <img src={item.icon} alt="right-icon" />
                )}
              </ListItem>
              {item.subitems && item.subitems.length > 0 && (
                <Collapse
                  sx={{
                    flexDirection: 'column',
                    alignSelf: 'stretch',
                    paddingX: 0
                  }}
                  in={openItems[key]}
                >
                  {renderSubitems(item.subitems, key, depth + 1)}
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
      </List>
    );
  };

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        paddingX: 0,
        paddingY: 0
      }}
    >
      {menuData.map((menuItem, index) => {
        const key = `menu-${index}`;
        return (
          <React.Fragment key={key}>
            <ListItem
              button
              onClick={() => {
                handleToggle(key);
                handleChangedIcon(key);
                if (menuItem.status == true) {
                  handleNavigation(menuItem.rutaPrincipal);
                  handleItemSelect(menuItem.title);
                }
              }}
              sx={{
                display: 'flex',
                height: '56px',
                paddingY: 0,
                paddingX: '18px',
                alignItems: 'center',
                gap: '20px'
              }}
            >
              <img src={menuItem.icon} alt={menuItem.title} />
              <Typography
                variant="body1"
                sx={{
                  flex: '1 0 0',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '24px',
                  // Aplica un estilo condicional basado en isSelected
                  color:
                    selectedItem === menuItem.title
                      ? 'blue'
                      : 'var(--boton-800-primario, #3677DE)'
                }}
              >
                {menuItem.title}
              </Typography>
              {menuItem.status == false ? (
                selectedChanges[key] ? (
                  <img src={AbajoCLIENTE} alt="AbajoCLIENTE" />
                ) : (
                  <img
                    src={FlechaHaciaAbajoSIDENAV}
                    alt="FlechaHaciaAbajoSIDENAV"
                  />
                )
              ) : selectedItem === menuItem.title ? (
                <img
                  src={FlechaHorizontalSIDENAV}
                  alt="FlechaHorizontalSIDENAV"
                />
              ) : (
                <img />
              )}
            </ListItem>
            {menuItem.submenus && menuItem.submenus.length > 0 && (
              <Collapse
                sx={{
                  flexDirection: 'column',
                  alignSelf: 'stretch',
                  paddingX: 0
                }}
                in={openItems[key]}
              >
                {renderSubitems(menuItem.submenus, key)}
              </Collapse>
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default SelectedListItem;
