import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { PaperProps } from '@mui/material/Paper';
import {
  Box,
  DialogContent,
  SelectChangeEvent,
  Typography
} from '@mui/material';

import TextFieldNuevoTablero from '../TextField/TextFieldNuevoTablero';
import ColorButtonStyle from '../ColorButton/ColorButtonStyle';

interface Props {
  open: boolean;
  handleOpen?: React.MouseEventHandler<HTMLButtonElement>;
  handleClose?: () => void;
  direction?: any;
  data: { description: string; titulo: string };
  paper: PaperProps;
}

const TransitionLeft = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DialogEditarPlantilla: React.FC<Props> = ({
  open,
  handleOpen,
  handleClose,
  data,
  paper
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [nombreTablero, setNombreTablero] = useState('');
  const [descripcionTablero, setDescripcionTablero] = useState('');

  useEffect(() => {
    if (open) {
      setDescripcionTablero(data.description);
      setNombreTablero(data.titulo);
    }
  }, [open, data.description, data.titulo]);

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { name, value } = event.target;

    if (name === 'txtdescripcionTablero') {
      event.preventDefault();
      setDescripcionTablero(value);
    }

    if (name === 'txtnombreTablero') {
      event.preventDefault();
      setNombreTablero(value);
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransitionLeft}
      onClose={handleClose}
      maxWidth={'md'}
      PaperProps={paper}
      disableEscapeKeyDown={true}
      BackdropProps={{
        sx: {
          opacity: '0.25 !important',
          backgroundColor: '#232323'
        }
      }}
    >
      <DialogContent sx={{ padding: { xs: 1, md: 2, lgx: 2 } }}>
        <Box
          sx={{
            paddingTop: '8px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px',
            alignSelf: 'stretch',
            width: '25rem',
            padding: 0,
            margin: 0
          }}
        >
          <Box
            sx={{
              display: 'flex',
              height: '44px',
              alignItems: 'center',
              gap: '20px',
              flexShrink: 0,
              alignSelf: 'stretch',
              textAlign: 'center'
            }}
          >
            <Typography
              color="primary"
              sx={{
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: 'Gotham',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '28px'
              }}
            >
              Editar Plantilla
            </Typography>
          </Box>

          {/* CONTENIDO STEP 2 */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '28px',
              alignSelf: 'stretch'
            }}
          >
            {/* INPUTS */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '16px',
                alignSelf: 'stretch'
              }}
            >
              <TextFieldNuevoTablero
                height="56px"
                handleChangeInputValues={handleChangeInput}
                label="Nombre Tablero"
                title=""
                titleInput=""
                nameValue={'txtnombreTablero'}
                type="text"
                required={true}
                value={nombreTablero}
              />
              <TextFieldNuevoTablero
                multiline={true}
                height="76px"
                handleChangeInputValues={handleChangeInput}
                label="Descripción Tablero"
                title=""
                titleInput=""
                nameValue={'txtdescripcionTablero'}
                type="text"
                required={true}
                value={descripcionTablero}
              />
            </Box>
          </Box>
          {/* FOOTER */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              marginY: '30px'
            }}
          >
            <Box
              onClick={handleClose}
              sx={{
                display: 'flex',
                width: '15rem',
                height: '56px',
                padding: '2px 10px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '12px',
                cursor: 'pointer'
              }}
            >
              <Typography
                sx={{
                  color: '#3677DE',
                  textAlign: 'center',
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: 'Poppins',
                  fontSize: '15px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '22px',
                  textDecorationLine: 'underline'
                }}
              >
                CANCELAR
              </Typography>
            </Box>
            <ColorButtonStyle
              handleEvent={handlePreviousStep}
              title="GUARDAR"
              hover="#69C4DE"
              pasive={
                nombreTablero.length > 0 && descripcionTablero.length > 0
                  ? false
                  : true
              }
              imgIcon={false}
              background="#3677DE"
              textcolor="#fff"
              widthdefault="20rem"
              heightdefault="56px"
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogEditarPlantilla;
